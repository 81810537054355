import { useEffect } from "react";
import { processService } from '@/app/services/api/backend/process/processService';
import { useWebSocket } from "@/app/context/websocket-context";
import Logger from "@/app/utils/logger";
import axios from "axios";

const ProcessSubscriber = () => {
  const { subscribeToProcess } = useWebSocket();

  useEffect(() => {
    const getProcessExternalIds = async () => {
      try {
        if(!(await isUserLoggedIn()))
          return;

        const processWithNoResponse = (await processService.getProcessByUserWithResponseFilter(false)).data;
        const processIds = processWithNoResponse.map(p => p.processExternalId);

        for (let i = 0; i < processIds.length; i++) {
          subscribeToProcess(processIds[i]);
        }
      } catch (error) {
        Logger.error(`ProcessSubscriber: Failed to fetch processes: ${JSON.stringify(error)} `);
      }
    };

    getProcessExternalIds();
  }, [subscribeToProcess]);

  const isUserLoggedIn = async () => {
    let userLogged = undefined;
    try{
      userLogged = await axios.get("/api/auth/me");
    } catch(error){
      return false;
    }        
    return (userLogged != undefined);
  }

  return null;
};

export default ProcessSubscriber;