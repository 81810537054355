import { useState, useEffect } from "react"
import Logger from "../utils/logger";

// Create a custom event for storage updates
const createStorageEvent = (key: string, value: any) =>
  new CustomEvent("local-storage-update", {
    detail: { key, value },
  })

// Type for the storage event listener
type StorageListener = (event: CustomEvent<{ key: string; value: any }>) => void

export function useStorageSync<T>(key: string, initialValue: T) {
  const [value, setValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch {
      return initialValue
    }
  })

  // Listen for changes
  useEffect(() => {
    const handleStorageChange = ((event: CustomEvent<{ key: string; value: any }>) => {
      if (event.detail.key === key) {
        setValue(event.detail.value)
      }
    }) as any

    // Add event listener
    window.addEventListener("local-storage-update", handleStorageChange)

    return () => {
      window.removeEventListener("local-storage-update", handleStorageChange)
    }
  }, [key])

  // Update function that both sets localStorage and dispatches event
  const updateValue = (newValue: T) => {
    try {
      // Update localStorage
      localStorage.setItem(key, JSON.stringify(newValue))
      // Dispatch event for other components
      window?.dispatchEvent(createStorageEvent(key, newValue))
      // Update local state
      setValue(newValue)
    } catch (error) {
      Logger.error("error updating localstorage value for notification")
    }
  }

  return [value, updateValue] as const
}

