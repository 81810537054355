import { Process } from '@/app/types/process';
import axiosHelper from '@/app/utils/axiosHelper';
import { AxiosResponse } from 'axios';

class ProcessService {
  private readonly baseUrl: string;
  constructor() {
    this.baseUrl = `${process.env.BACKEND_URL}/backend/process`;
  }

  async getAllProcessByUser(): Promise<AxiosResponse<Process[]>> {
    const url = `${this.baseUrl}`;
    return await axiosHelper.get(`api/proxy?url=${url}`);
  }
  async getProcessByUserWithResponseFilter(withResponse:boolean): Promise<AxiosResponse<Process[]>> {
    const url = `${this.baseUrl}?withResponse=${withResponse}`;
    return await axiosHelper.get(`api/proxy?url=${url}`);
  }
    
}

export const processService = new ProcessService();
