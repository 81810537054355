import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface ToastAlertProps {
  isOpen: boolean;
  message: string;
  severity: 'info' | 'error' | 'warning' | 'success';
  closeToastAlert: () => void;
  autoHideDuration?: number;
}
const ToastAlert: React.FC<ToastAlertProps> = ({
  isOpen,
  message,
  severity,
  closeToastAlert,
  autoHideDuration = 4000,
}) => {
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      open={isOpen}
      onClose={closeToastAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={closeToastAlert}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastAlert;
