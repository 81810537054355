import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Close, Send } from '@mui/icons-material';
import { supportService } from '@/app/services/api/backend/support/support.service';
import { green } from '@mui/material/colors';
import { cn } from '@/app/utils/lib/utils';
import { buttonVariants } from '../landing-page-components/ui/button';
import { useToastAlert } from '../../context/toast-alert-context';

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

interface SupportProps {
  isOpen: boolean;
  closeModal: () => void;
}

const Support: React.FC<SupportProps> = ({ isOpen, closeModal }) => {
  const ticketTypes = ['Issue', 'Incident', 'Feedback', 'Feature Request'];
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [subject, setSubject] = useState('');
  const [type, setType] = useState<string>('Issue');
  const [message, setMessage] = useState('');
  const { openToastAlert } = useToastAlert();
  /**
   * Call the submit ticket api
   * @returns
   */
  const submitTicket = async () => {
    setIsApiLoading(true);
    try {
      const res = await supportService.submitTicket({
        description: message,
        subject,
        type,
      });

      openToastAlert(
        'Your request has been submitted successfully. Please check your email for updates.',
        'success',
      );
      closeModal();
      return res;
    } catch (error) {
      openToastAlert(
        'Failed to create ticket. Please reach out to gdi@fugro.com.',
        'error',
      );
      throw error;
    } finally {
      setIsApiLoading(false);
    }
  };
  const isFormvalid = () => subject && message;
  if (!isOpen) return null;
  return (
    <Modal
      open={true}
      onClose={() => {}}
      aria-labelledby="gdi-support-modal-title"
      aria-describedby="gdi-support-modal-description"
    >
      <Box component={'div'} sx={style}>
        <IconButton
          onClick={() => closeModal()}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: (theme: any) => theme.palette.grey[500],
          }}
        >
          <Close 
            sx={{
              color: 'black',
              '&:hover': {
              backgroundColor: 'red',
              color: 'white',
              },
              }} 
            />
        </IconButton>
        <Grid container direction={'column'} gap={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component={'h6'}>
              Please fill the form to receive support from the Geo-data
              Intelligence team. We will get in touch with you at the earliest.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="type">Support Category</FormLabel>
              <Select
                onChange={(event) => setType(event.target.value)}
                value={type}
                fullWidth
                id="type"
              >
                {ticketTypes.map((ticketType) => (
                  <MenuItem key={ticketType} value={ticketType}>
                    {ticketType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="subject">Subject</FormLabel>
              <TextField
                id="subject"
                onChange={(event) => setSubject(event.target.value)}
                placeholder="Subject"
                variant="outlined"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel htmlFor="message">How can we help you?</FormLabel>
              <TextField
                id="message"
                onChange={(event) => setMessage(event.target.value)}
                multiline
                rows={3}
                placeholder={
                  type === 'Issue'
                    ? 'Please describe your issue'
                    : 'Please provide your feedback'
                }
                variant="outlined"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={'center'}
            alignItems="center"
            direction={'row'}
          >
            {/* <Button
                    onClick={submitTicket}
                    disabled={isApiLoading || !isFormvalid()}
                    variant="contained"
                    endIcon={<Send />}
                  >
                    Submit
                  </Button> */}
            <button
              onClick={submitTicket}
              disabled={isApiLoading || !isFormvalid()}
              className={cn(
                buttonVariants({ variant: 'secondary' }),
                `bg-action-foreground flex items-center justify-center px-4 py-2 rounded-lg text-base text-secondary-foreground font-semibold transition 
                      ${
                        isApiLoading || !isFormvalid()
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-blue-500 text-white hover:bg-blue-600'
                      }`,
              )}
            >
              Submit
              <Send className="ml-2" sx={{ fontSize: 12 }} />
            </button>
            {isApiLoading && (
              <CircularProgress
                size={44}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Support;
